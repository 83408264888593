// firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAGmYw3Udek8wJ_oEw03CxwVtRXsIqhlcs",
    authDomain: "siddhivinayak-d8314.firebaseapp.com",
    projectId: "siddhivinayak-d8314",
    storageBucket: "siddhivinayak-d8314.appspot.com",
    messagingSenderId: "602830512944",
    appId: "1:602830512944:web:23294d0bf72ceb66cddef5",
    measurementId: "G-K8JZZMMEFQ"
  };

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export default firestore;
