// ContactUs.js

import React, { useState } from 'react';
import './ContactUs.css';
import firestore from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const docRef = await addDoc(collection(firestore, 'contacts'), {
        name,
        number,
        email,
        message
      });
      console.log('Document written with ID: ', docRef.id);
      // Handle successful submission
    } catch (error) {
      console.error('Error adding document: ', error);
      // Handle submission error
    }
    // Clear form fields
    setName('');
    setNumber('');
    setEmail('');
    setMessage('');
  };

  const handleNumberChange = (event) => {
    const inputNumber = event.target.value.replace(/\D/g, '').slice(0, 10); // Remove non-numeric characters and limit to 10 digits
    setNumber(inputNumber);
  };

  return (
    <div className="contact-form-container">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input type="text" placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div className="form-group">
          <input type="tel" placeholder='Phone Number' value={number} onChange={handleNumberChange} required />
        </div>
        <div className="form-group">
          <input type="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <textarea placeholder='Message' value={message} onChange={(e) => setMessage(e.target.value)} rows="4" required></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ContactUs;
