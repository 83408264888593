import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import Navbar from './Components/NavBar';
import ContactUs from './Components/ContactUs';

function App() {
  const homeRef = useRef(null);
  const menuRef = useRef(null);
  const contactUsRef = useRef(null);
  const aboutUsRef = useRef(null);

  const sectionRefs = {
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
  };
  
  const [selectedCategory, setSelectedCategory] = useState('Appetizer');
  const [categories, setCategories] = useState([]);
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);

  const [typedText, setTypedText] = useState('');
  const textToType = "Hotel Siddhivinayak";

    const scrollToSection = (page) => {
      let targetRef;
  switch (page) {
    case "home":
      targetRef = homeRef;
      break;
    case "menu":
      targetRef = menuRef;
      break;
    case "contact":
      targetRef = contactUsRef;
      break;
    case "about":
      targetRef = aboutUsRef;
      break;
    default:
      return;
  }
  if (targetRef && targetRef.current) {
    targetRef.current.scrollIntoView({ behavior: 'smooth' });
  }
      
    };

  const menuItems = [
    { id: 1, name: 'Item 1', category: 'Appetizer' },
    { id: 2, name: 'Item 2', category: 'Main Course' },
    { id: 3, name: 'Item 3', category: 'Dessert' },
    { id: 4, name: 'Item 4', category: 'Dessert' },
    { id: 5, name: 'Item 5', category: 'Dessert' },
    { id: 6, name: 'Item 6', category: 'Dessert' },
    { id: 7, name: 'Item 7', category: 'Dessert' },
  ];

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex <= textToType.length) {
        setTypedText(textToType.substring(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 100); // Adjust typing speed here
    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   const categories = [...new Set(menuItems.map((item) => item.category))];
  //   setCategories(categories);

  //   const filteredMenuItems = menuItems.filter((item) =>
  //     selectedCategory ? item.category === selectedCategory : true
  //   );
  //   setFilteredMenuItems(filteredMenuItems);
  // }, [selectedCategory]);

  

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="App">
      <Navbar scrollToSection={scrollToSection} />
      <main>
        <section ref={sectionRefs.section1} id="home" className="cover-photo">
        
        </section>
        {/* <section ref={sectionRefs.section2} id="menu"  >
        <h1>Menu List</h1>
          <div className="categories-row">
            {categories.map((category) => (
              <button
                key={category}
                className={selectedCategory === category ? 'selected' : ''}
                onClick={() => handleCategorySelect(category)}
              >
                {category}
              </button>
            ))}
          </div>
          <div className="menu-grid">
            {filteredMenuItems.map((item) => (
              <div key={item.id} className="menu-item">
                <h3>{item.name}</h3>
                <p>{item.category}</p>
              </div>
            ))}
          </div>
        </section>
        <section ref={sectionRefs.section3} id="aboutus" >
          <h1>About</h1>
        </section> */}
        <section ref={contactUsRef} id="contactus">

        <ContactUs />
        </section>
     
      </main>
    </div>
  );
}

export default App;