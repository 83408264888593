// Navbar.js

import React, { useState } from 'react';
import './Navbar.css'; // Import your navbar styles here

const Navbar = ({scrollToSection}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        
        <div className="logo">
          <img src={require('../assets/logo1.png')} alt="Logo" /> {/* Replace '/path/to/your/logo.png' with the actual path to your logo image */}
        </div>
        <div className={`menu ${isOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="#home" onClick={() => scrollToSection('home')}>Home</a></li>
            <li><a href="#menu" onClick={() => scrollToSection('menu')}>Menu</a></li>
            <li><a href="#contactus" onClick={() => scrollToSection('contactus')}>Contact us</a></li>
            <li><a href="#aboutus" onClick={() => scrollToSection('aboutus')}>About us</a></li>
          </ul>
        </div>
        <div className="menu-toggle" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
